import React from "react";
import { Table, TableContainer } from "@mui/material";
import {
  getWorkoutGroupSetExercises,
  workoutGroupSetInnerRoundsInfo,
} from "features/workouts/workout.types";
import { DisplayValueType } from "./group-set-data-table-data";
import { GroupSetDataTableBody } from "./group-set-data-table-body";
import { GroupSetDataTableHeader } from "./group-set-data-table-header";
import { RowHeight } from "./group-set-data-table-data";
import { WorkoutGroupSet } from "app/api-base";

interface Props {
  groupSet: WorkoutGroupSet;
  displayValue: DisplayValueType;
}

export function GroupSetDataTable({ groupSet, displayValue }: Props) {
  const roundsInfo = workoutGroupSetInnerRoundsInfo(groupSet);
  const exerciseGroups = getWorkoutGroupSetExercises(groupSet);

  const numRows = exerciseGroups.length + (roundsInfo.multiRounds ? 1 : 0) + 1;

  return (
    <div style={{ height: numRows * RowHeight }}>
      <TableContainer>
        <Table>
          <GroupSetDataTableHeader groupSet={groupSet} />
          <GroupSetDataTableBody groupSet={groupSet} displayValue={displayValue} />
        </Table>
      </TableContainer>
    </div>
  );
}
