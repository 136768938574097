import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GarminUser } from "features/auth/auth.types";
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/auth";
import { garminSignIn as garminSignInMutation } from "app/api-base";

interface SignInArgs {
  email: string;
  password: string;
}

export const garminSignIn = createAsyncThunk(
  "auth/garminSignInStatus",
  async ({ email, password }: SignInArgs, { rejectWithValue }) => {
    try {
      const response = await API.graphql<any>({
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        query: garminSignInMutation,
        variables: {
          username: email,
          password,
          input: {},
        },
      });
      if (response.data) {
        return response.data.garminSignIn;
      } else if (response.errors) {
        return rejectWithValue({
          name: "GRAPHQL_ERROR",
          message: response.errors.map(({ message }) => message).join(","),
        });
      }
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

interface AuthState {
  garminSignInInProgress: boolean;
  garminSignInError?: Error;
  garminUser?: GarminUser;
  isGarminAuthenticated: boolean;
}

const initialState: AuthState = {
  isGarminAuthenticated: false,
  garminSignInInProgress: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // garminSignIn reducers
    builder
      .addCase(garminSignIn.pending, (state) => {
        state.garminSignInInProgress = true;
        state.isGarminAuthenticated = false;
        state.garminUser = undefined;
        state.garminSignInError = undefined;
      })
      .addCase(garminSignIn.fulfilled, (state, { payload }) => {
        state.garminSignInInProgress = false;
        state.isGarminAuthenticated = true;
        state.garminUser = payload;
        state.garminSignInError = undefined;
      })
      .addCase(garminSignIn.rejected, (state, { payload }) => {
        state.garminSignInInProgress = false;
        state.isGarminAuthenticated = false;
        state.garminUser = undefined;
        state.garminSignInError = {
          name: (payload as Error).name,
          message: (payload as Error).message,
        };
      });
  },
});

export const authReducer = authSlice.reducer;
