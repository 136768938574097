/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type GarminProfile = {
  __typename: "GarminProfile";
  userProfileId: number;
  username: string;
  emailAddress: string;
};

export type CreateActivityInput = {
  id?: string | null;
  name: string;
  timestamp?: string | null;
  trainingEffect: TrainingEffectInput;
  activityHeartRateId: string;
};

export type TrainingEffectInput = {
  anaerobic: number;
  aerobic: number;
  trainingLoad: number;
};

export type ModelActivityConditionInput = {
  name?: ModelStringInput | null;
  timestamp?: ModelStringInput | null;
  and?: Array<ModelActivityConditionInput | null> | null;
  or?: Array<ModelActivityConditionInput | null> | null;
  not?: ModelActivityConditionInput | null;
  activityHeartRateId?: ModelIDInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type Activity = {
  __typename: "Activity";
  id: string;
  name: string;
  timestamp?: string | null;
  heartRate: HeartRate;
  workout?: ModelWorkoutGroupSetConnection | null;
  trainingEffect: TrainingEffect;
  createdAt: string;
  updatedAt: string;
  activityHeartRateId: string;
  owner?: string | null;
};

export type HeartRate = {
  __typename: "HeartRate";
  id: string;
  activity: Activity;
  data: Array<HeartRateData>;
  createdAt: string;
  updatedAt: string;
  heartRateActivityId: string;
  owner?: string | null;
};

export type HeartRateData = {
  __typename: "HeartRateData";
  time: number;
  timestamp: string;
  value: number;
};

export type ModelWorkoutGroupSetConnection = {
  __typename: "ModelWorkoutGroupSetConnection";
  items: Array<WorkoutGroupSet | null>;
  nextToken?: string | null;
};

export type WorkoutGroupSet = {
  __typename: "WorkoutGroupSet";
  id: string;
  type: string;
  activity: Activity;
  wod: Wod;
  rounds: Array<Round>;
  createdAt: string;
  updatedAt: string;
  activityWorkoutId: string;
  workoutGroupSetWodId: string;
  owner?: string | null;
};

export type Wod = {
  __typename: "Wod";
  id: string;
  duration: Duration;
  steps: Array<WodStep>;
  groupSet: WorkoutGroupSet;
  createdAt: string;
  updatedAt: string;
  wodGroupSetId: string;
  owner?: string | null;
};

export type Duration = {
  __typename: "Duration";
  type: string;
  value?: number | null;
};

export type WodStep = {
  __typename: "WodStep";
  exercise?: WodExercise | null;
  round?: WodRound | null;
};

export type WodExercise = {
  __typename: "WodExercise";
  index: number;
  exercise: Exercise;
  duration: Duration;
  target: Target;
};

export type Exercise = {
  __typename: "Exercise";
  category: string;
  name: string;
};

export type Target = {
  __typename: "Target";
  type: string;
  value: number;
};

export type WodRound = {
  __typename: "WodRound";
  index: number;
  exercises: Array<WodExercise>;
  duration: Duration;
};

export type Round = {
  __typename: "Round";
  index: number;
  sets: Array<RoundOrSet>;
};

export type RoundOrSet = {
  __typename: "RoundOrSet";
  round?: Round | null;
  set?: Set | null;
};

export type Set = {
  __typename: "Set";
  index: number;
  duration: number;
  timestamp: string;
  time: number;
  exercise: Exercise;
  setType: string;
  repetitionCount: number;
  weight?: number | null;
};

export type TrainingEffect = {
  __typename: "TrainingEffect";
  anaerobic: number;
  aerobic: number;
  trainingLoad: number;
};

export type UpdateActivityInput = {
  id: string;
  name?: string | null;
  timestamp?: string | null;
  trainingEffect?: TrainingEffectInput | null;
  activityHeartRateId?: string | null;
};

export type DeleteActivityInput = {
  id: string;
};

export type CreateHeartRateInput = {
  id?: string | null;
  data: Array<HeartRateDataInput>;
  heartRateActivityId: string;
};

export type HeartRateDataInput = {
  time: number;
  timestamp: string;
  value: number;
};

export type ModelHeartRateConditionInput = {
  and?: Array<ModelHeartRateConditionInput | null> | null;
  or?: Array<ModelHeartRateConditionInput | null> | null;
  not?: ModelHeartRateConditionInput | null;
  heartRateActivityId?: ModelIDInput | null;
};

export type UpdateHeartRateInput = {
  id: string;
  data?: Array<HeartRateDataInput> | null;
  heartRateActivityId?: string | null;
};

export type DeleteHeartRateInput = {
  id: string;
};

export type CreateWodInput = {
  id?: string | null;
  duration: DurationInput;
  steps: Array<WodStepInput>;
  wodGroupSetId: string;
};

export type DurationInput = {
  type: string;
  value?: number | null;
};

export type WodStepInput = {
  exercise?: WodExerciseInput | null;
  round?: WodRoundInput | null;
};

export type WodExerciseInput = {
  index: number;
  exercise: ExerciseInput;
  duration: DurationInput;
  target: TargetInput;
};

export type ExerciseInput = {
  category: string;
  name: string;
};

export type TargetInput = {
  type: string;
  value: number;
};

export type WodRoundInput = {
  index: number;
  exercises: Array<WodExerciseInput>;
  duration: DurationInput;
};

export type ModelWodConditionInput = {
  and?: Array<ModelWodConditionInput | null> | null;
  or?: Array<ModelWodConditionInput | null> | null;
  not?: ModelWodConditionInput | null;
  wodGroupSetId?: ModelIDInput | null;
};

export type UpdateWodInput = {
  id: string;
  duration?: DurationInput | null;
  steps?: Array<WodStepInput> | null;
  wodGroupSetId?: string | null;
};

export type DeleteWodInput = {
  id: string;
};

export type CreateWorkoutGroupSetInput = {
  id?: string | null;
  type: string;
  rounds: Array<RoundInput>;
  activityWorkoutId: string;
  workoutGroupSetWodId: string;
};

export type RoundInput = {
  index: number;
  sets: Array<RoundOrSetInput>;
};

export type RoundOrSetInput = {
  round?: RoundInput | null;
  set?: SetInput | null;
};

export type SetInput = {
  index: number;
  duration: number;
  timestamp: string;
  time: number;
  exercise: ExerciseInput;
  setType: string;
  repetitionCount: number;
  weight?: number | null;
};

export type ModelWorkoutGroupSetConditionInput = {
  type?: ModelStringInput | null;
  and?: Array<ModelWorkoutGroupSetConditionInput | null> | null;
  or?: Array<ModelWorkoutGroupSetConditionInput | null> | null;
  not?: ModelWorkoutGroupSetConditionInput | null;
  activityWorkoutId?: ModelIDInput | null;
  workoutGroupSetWodId?: ModelIDInput | null;
};

export type UpdateWorkoutGroupSetInput = {
  id: string;
  type?: string | null;
  rounds?: Array<RoundInput> | null;
  activityWorkoutId?: string | null;
  workoutGroupSetWodId?: string | null;
};

export type DeleteWorkoutGroupSetInput = {
  id: string;
};

export type GarminActivity = {
  __typename: "GarminActivity";
  id: string;
  workoutId?: number | null;
  name: string;
  description?: string | null;
  startTime: string;
  duration: number;
  type: string;
  calories: number;
  averageHR: number;
  maxHR: number;
};

export type ModelActivityFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  timestamp?: ModelStringInput | null;
  and?: Array<ModelActivityFilterInput | null> | null;
  or?: Array<ModelActivityFilterInput | null> | null;
  not?: ModelActivityFilterInput | null;
  activityHeartRateId?: ModelIDInput | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export type ModelActivityConnection = {
  __typename: "ModelActivityConnection";
  items: Array<Activity | null>;
  nextToken?: string | null;
};

export type ModelHeartRateFilterInput = {
  id?: ModelIDInput | null;
  and?: Array<ModelHeartRateFilterInput | null> | null;
  or?: Array<ModelHeartRateFilterInput | null> | null;
  not?: ModelHeartRateFilterInput | null;
  heartRateActivityId?: ModelIDInput | null;
};

export type ModelHeartRateConnection = {
  __typename: "ModelHeartRateConnection";
  items: Array<HeartRate | null>;
  nextToken?: string | null;
};

export type ModelWodFilterInput = {
  id?: ModelIDInput | null;
  and?: Array<ModelWodFilterInput | null> | null;
  or?: Array<ModelWodFilterInput | null> | null;
  not?: ModelWodFilterInput | null;
  wodGroupSetId?: ModelIDInput | null;
};

export type ModelWodConnection = {
  __typename: "ModelWodConnection";
  items: Array<Wod | null>;
  nextToken?: string | null;
};

export type ModelWorkoutGroupSetFilterInput = {
  id?: ModelIDInput | null;
  type?: ModelStringInput | null;
  and?: Array<ModelWorkoutGroupSetFilterInput | null> | null;
  or?: Array<ModelWorkoutGroupSetFilterInput | null> | null;
  not?: ModelWorkoutGroupSetFilterInput | null;
  activityWorkoutId?: ModelIDInput | null;
  workoutGroupSetWodId?: ModelIDInput | null;
};

export type GarminSignInMutationVariables = {
  username: string;
  password: string;
};

export type GarminSignInMutation = {
  garminSignIn?: {
    __typename: "GarminProfile";
    userProfileId: number;
    username: string;
    emailAddress: string;
  } | null;
};

export type ImportGarminActivityMutationVariables = {
  id: string;
};

export type ImportGarminActivityMutation = {
  importGarminActivity?: string | null;
};

export type CreateActivityMutationVariables = {
  input: CreateActivityInput;
  condition?: ModelActivityConditionInput | null;
};

export type CreateActivityMutation = {
  createActivity?: {
    __typename: "Activity";
    id: string;
    name: string;
    timestamp?: string | null;
    heartRate: {
      __typename: "HeartRate";
      id: string;
      activity: {
        __typename: "Activity";
        id: string;
        name: string;
        timestamp?: string | null;
        createdAt: string;
        updatedAt: string;
        activityHeartRateId: string;
        owner?: string | null;
      };
      data: Array<{
        __typename: "HeartRateData";
        time: number;
        timestamp: string;
        value: number;
      }>;
      createdAt: string;
      updatedAt: string;
      heartRateActivityId: string;
      owner?: string | null;
    };
    workout?: {
      __typename: "ModelWorkoutGroupSetConnection";
      items: Array<{
        __typename: "WorkoutGroupSet";
        id: string;
        type: string;
        createdAt: string;
        updatedAt: string;
        activityWorkoutId: string;
        workoutGroupSetWodId: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    trainingEffect: {
      __typename: "TrainingEffect";
      anaerobic: number;
      aerobic: number;
      trainingLoad: number;
    };
    createdAt: string;
    updatedAt: string;
    activityHeartRateId: string;
    owner?: string | null;
  } | null;
};

export type UpdateActivityMutationVariables = {
  input: UpdateActivityInput;
  condition?: ModelActivityConditionInput | null;
};

export type UpdateActivityMutation = {
  updateActivity?: {
    __typename: "Activity";
    id: string;
    name: string;
    timestamp?: string | null;
    heartRate: {
      __typename: "HeartRate";
      id: string;
      activity: {
        __typename: "Activity";
        id: string;
        name: string;
        timestamp?: string | null;
        createdAt: string;
        updatedAt: string;
        activityHeartRateId: string;
        owner?: string | null;
      };
      data: Array<{
        __typename: "HeartRateData";
        time: number;
        timestamp: string;
        value: number;
      }>;
      createdAt: string;
      updatedAt: string;
      heartRateActivityId: string;
      owner?: string | null;
    };
    workout?: {
      __typename: "ModelWorkoutGroupSetConnection";
      items: Array<{
        __typename: "WorkoutGroupSet";
        id: string;
        type: string;
        createdAt: string;
        updatedAt: string;
        activityWorkoutId: string;
        workoutGroupSetWodId: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    trainingEffect: {
      __typename: "TrainingEffect";
      anaerobic: number;
      aerobic: number;
      trainingLoad: number;
    };
    createdAt: string;
    updatedAt: string;
    activityHeartRateId: string;
    owner?: string | null;
  } | null;
};

export type DeleteActivityMutationVariables = {
  input: DeleteActivityInput;
  condition?: ModelActivityConditionInput | null;
};

export type DeleteActivityMutation = {
  deleteActivity?: {
    __typename: "Activity";
    id: string;
    name: string;
    timestamp?: string | null;
    heartRate: {
      __typename: "HeartRate";
      id: string;
      activity: {
        __typename: "Activity";
        id: string;
        name: string;
        timestamp?: string | null;
        createdAt: string;
        updatedAt: string;
        activityHeartRateId: string;
        owner?: string | null;
      };
      data: Array<{
        __typename: "HeartRateData";
        time: number;
        timestamp: string;
        value: number;
      }>;
      createdAt: string;
      updatedAt: string;
      heartRateActivityId: string;
      owner?: string | null;
    };
    workout?: {
      __typename: "ModelWorkoutGroupSetConnection";
      items: Array<{
        __typename: "WorkoutGroupSet";
        id: string;
        type: string;
        createdAt: string;
        updatedAt: string;
        activityWorkoutId: string;
        workoutGroupSetWodId: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    trainingEffect: {
      __typename: "TrainingEffect";
      anaerobic: number;
      aerobic: number;
      trainingLoad: number;
    };
    createdAt: string;
    updatedAt: string;
    activityHeartRateId: string;
    owner?: string | null;
  } | null;
};

export type CreateHeartRateMutationVariables = {
  input: CreateHeartRateInput;
  condition?: ModelHeartRateConditionInput | null;
};

export type CreateHeartRateMutation = {
  createHeartRate?: {
    __typename: "HeartRate";
    id: string;
    activity: {
      __typename: "Activity";
      id: string;
      name: string;
      timestamp?: string | null;
      heartRate: {
        __typename: "HeartRate";
        id: string;
        createdAt: string;
        updatedAt: string;
        heartRateActivityId: string;
        owner?: string | null;
      };
      workout?: {
        __typename: "ModelWorkoutGroupSetConnection";
        nextToken?: string | null;
      } | null;
      trainingEffect: {
        __typename: "TrainingEffect";
        anaerobic: number;
        aerobic: number;
        trainingLoad: number;
      };
      createdAt: string;
      updatedAt: string;
      activityHeartRateId: string;
      owner?: string | null;
    };
    data: Array<{
      __typename: "HeartRateData";
      time: number;
      timestamp: string;
      value: number;
    }>;
    createdAt: string;
    updatedAt: string;
    heartRateActivityId: string;
    owner?: string | null;
  } | null;
};

export type UpdateHeartRateMutationVariables = {
  input: UpdateHeartRateInput;
  condition?: ModelHeartRateConditionInput | null;
};

export type UpdateHeartRateMutation = {
  updateHeartRate?: {
    __typename: "HeartRate";
    id: string;
    activity: {
      __typename: "Activity";
      id: string;
      name: string;
      timestamp?: string | null;
      heartRate: {
        __typename: "HeartRate";
        id: string;
        createdAt: string;
        updatedAt: string;
        heartRateActivityId: string;
        owner?: string | null;
      };
      workout?: {
        __typename: "ModelWorkoutGroupSetConnection";
        nextToken?: string | null;
      } | null;
      trainingEffect: {
        __typename: "TrainingEffect";
        anaerobic: number;
        aerobic: number;
        trainingLoad: number;
      };
      createdAt: string;
      updatedAt: string;
      activityHeartRateId: string;
      owner?: string | null;
    };
    data: Array<{
      __typename: "HeartRateData";
      time: number;
      timestamp: string;
      value: number;
    }>;
    createdAt: string;
    updatedAt: string;
    heartRateActivityId: string;
    owner?: string | null;
  } | null;
};

export type DeleteHeartRateMutationVariables = {
  input: DeleteHeartRateInput;
  condition?: ModelHeartRateConditionInput | null;
};

export type DeleteHeartRateMutation = {
  deleteHeartRate?: {
    __typename: "HeartRate";
    id: string;
    activity: {
      __typename: "Activity";
      id: string;
      name: string;
      timestamp?: string | null;
      heartRate: {
        __typename: "HeartRate";
        id: string;
        createdAt: string;
        updatedAt: string;
        heartRateActivityId: string;
        owner?: string | null;
      };
      workout?: {
        __typename: "ModelWorkoutGroupSetConnection";
        nextToken?: string | null;
      } | null;
      trainingEffect: {
        __typename: "TrainingEffect";
        anaerobic: number;
        aerobic: number;
        trainingLoad: number;
      };
      createdAt: string;
      updatedAt: string;
      activityHeartRateId: string;
      owner?: string | null;
    };
    data: Array<{
      __typename: "HeartRateData";
      time: number;
      timestamp: string;
      value: number;
    }>;
    createdAt: string;
    updatedAt: string;
    heartRateActivityId: string;
    owner?: string | null;
  } | null;
};

export type CreateWodMutationVariables = {
  input: CreateWodInput;
  condition?: ModelWodConditionInput | null;
};

export type CreateWodMutation = {
  createWod?: {
    __typename: "Wod";
    id: string;
    duration: {
      __typename: "Duration";
      type: string;
      value?: number | null;
    };
    steps: Array<{
      __typename: "WodStep";
      exercise?: {
        __typename: "WodExercise";
        index: number;
      } | null;
      round?: {
        __typename: "WodRound";
        index: number;
      } | null;
    }>;
    groupSet: {
      __typename: "WorkoutGroupSet";
      id: string;
      type: string;
      activity: {
        __typename: "Activity";
        id: string;
        name: string;
        timestamp?: string | null;
        createdAt: string;
        updatedAt: string;
        activityHeartRateId: string;
        owner?: string | null;
      };
      wod: {
        __typename: "Wod";
        id: string;
        createdAt: string;
        updatedAt: string;
        wodGroupSetId: string;
        owner?: string | null;
      };
      rounds: Array<{
        __typename: "Round";
        index: number;
      }>;
      createdAt: string;
      updatedAt: string;
      activityWorkoutId: string;
      workoutGroupSetWodId: string;
      owner?: string | null;
    };
    createdAt: string;
    updatedAt: string;
    wodGroupSetId: string;
    owner?: string | null;
  } | null;
};

export type UpdateWodMutationVariables = {
  input: UpdateWodInput;
  condition?: ModelWodConditionInput | null;
};

export type UpdateWodMutation = {
  updateWod?: {
    __typename: "Wod";
    id: string;
    duration: {
      __typename: "Duration";
      type: string;
      value?: number | null;
    };
    steps: Array<{
      __typename: "WodStep";
      exercise?: {
        __typename: "WodExercise";
        index: number;
      } | null;
      round?: {
        __typename: "WodRound";
        index: number;
      } | null;
    }>;
    groupSet: {
      __typename: "WorkoutGroupSet";
      id: string;
      type: string;
      activity: {
        __typename: "Activity";
        id: string;
        name: string;
        timestamp?: string | null;
        createdAt: string;
        updatedAt: string;
        activityHeartRateId: string;
        owner?: string | null;
      };
      wod: {
        __typename: "Wod";
        id: string;
        createdAt: string;
        updatedAt: string;
        wodGroupSetId: string;
        owner?: string | null;
      };
      rounds: Array<{
        __typename: "Round";
        index: number;
      }>;
      createdAt: string;
      updatedAt: string;
      activityWorkoutId: string;
      workoutGroupSetWodId: string;
      owner?: string | null;
    };
    createdAt: string;
    updatedAt: string;
    wodGroupSetId: string;
    owner?: string | null;
  } | null;
};

export type DeleteWodMutationVariables = {
  input: DeleteWodInput;
  condition?: ModelWodConditionInput | null;
};

export type DeleteWodMutation = {
  deleteWod?: {
    __typename: "Wod";
    id: string;
    duration: {
      __typename: "Duration";
      type: string;
      value?: number | null;
    };
    steps: Array<{
      __typename: "WodStep";
      exercise?: {
        __typename: "WodExercise";
        index: number;
      } | null;
      round?: {
        __typename: "WodRound";
        index: number;
      } | null;
    }>;
    groupSet: {
      __typename: "WorkoutGroupSet";
      id: string;
      type: string;
      activity: {
        __typename: "Activity";
        id: string;
        name: string;
        timestamp?: string | null;
        createdAt: string;
        updatedAt: string;
        activityHeartRateId: string;
        owner?: string | null;
      };
      wod: {
        __typename: "Wod";
        id: string;
        createdAt: string;
        updatedAt: string;
        wodGroupSetId: string;
        owner?: string | null;
      };
      rounds: Array<{
        __typename: "Round";
        index: number;
      }>;
      createdAt: string;
      updatedAt: string;
      activityWorkoutId: string;
      workoutGroupSetWodId: string;
      owner?: string | null;
    };
    createdAt: string;
    updatedAt: string;
    wodGroupSetId: string;
    owner?: string | null;
  } | null;
};

export type CreateWorkoutGroupSetMutationVariables = {
  input: CreateWorkoutGroupSetInput;
  condition?: ModelWorkoutGroupSetConditionInput | null;
};

export type CreateWorkoutGroupSetMutation = {
  createWorkoutGroupSet?: {
    __typename: "WorkoutGroupSet";
    id: string;
    type: string;
    activity: {
      __typename: "Activity";
      id: string;
      name: string;
      timestamp?: string | null;
      heartRate: {
        __typename: "HeartRate";
        id: string;
        createdAt: string;
        updatedAt: string;
        heartRateActivityId: string;
        owner?: string | null;
      };
      workout?: {
        __typename: "ModelWorkoutGroupSetConnection";
        nextToken?: string | null;
      } | null;
      trainingEffect: {
        __typename: "TrainingEffect";
        anaerobic: number;
        aerobic: number;
        trainingLoad: number;
      };
      createdAt: string;
      updatedAt: string;
      activityHeartRateId: string;
      owner?: string | null;
    };
    wod: {
      __typename: "Wod";
      id: string;
      duration: {
        __typename: "Duration";
        type: string;
        value?: number | null;
      };
      groupSet: {
        __typename: "WorkoutGroupSet";
        id: string;
        type: string;
        createdAt: string;
        updatedAt: string;
        activityWorkoutId: string;
        workoutGroupSetWodId: string;
        owner?: string | null;
      };
      createdAt: string;
      updatedAt: string;
      wodGroupSetId: string;
      owner?: string | null;
    };
    rounds: Array<{
      __typename: "Round";
      index: number;
    }>;
    createdAt: string;
    updatedAt: string;
    activityWorkoutId: string;
    workoutGroupSetWodId: string;
    owner?: string | null;
  } | null;
};

export type UpdateWorkoutGroupSetMutationVariables = {
  input: UpdateWorkoutGroupSetInput;
  condition?: ModelWorkoutGroupSetConditionInput | null;
};

export type UpdateWorkoutGroupSetMutation = {
  updateWorkoutGroupSet?: {
    __typename: "WorkoutGroupSet";
    id: string;
    type: string;
    activity: {
      __typename: "Activity";
      id: string;
      name: string;
      timestamp?: string | null;
      heartRate: {
        __typename: "HeartRate";
        id: string;
        createdAt: string;
        updatedAt: string;
        heartRateActivityId: string;
        owner?: string | null;
      };
      workout?: {
        __typename: "ModelWorkoutGroupSetConnection";
        nextToken?: string | null;
      } | null;
      trainingEffect: {
        __typename: "TrainingEffect";
        anaerobic: number;
        aerobic: number;
        trainingLoad: number;
      };
      createdAt: string;
      updatedAt: string;
      activityHeartRateId: string;
      owner?: string | null;
    };
    wod: {
      __typename: "Wod";
      id: string;
      duration: {
        __typename: "Duration";
        type: string;
        value?: number | null;
      };
      groupSet: {
        __typename: "WorkoutGroupSet";
        id: string;
        type: string;
        createdAt: string;
        updatedAt: string;
        activityWorkoutId: string;
        workoutGroupSetWodId: string;
        owner?: string | null;
      };
      createdAt: string;
      updatedAt: string;
      wodGroupSetId: string;
      owner?: string | null;
    };
    rounds: Array<{
      __typename: "Round";
      index: number;
    }>;
    createdAt: string;
    updatedAt: string;
    activityWorkoutId: string;
    workoutGroupSetWodId: string;
    owner?: string | null;
  } | null;
};

export type DeleteWorkoutGroupSetMutationVariables = {
  input: DeleteWorkoutGroupSetInput;
  condition?: ModelWorkoutGroupSetConditionInput | null;
};

export type DeleteWorkoutGroupSetMutation = {
  deleteWorkoutGroupSet?: {
    __typename: "WorkoutGroupSet";
    id: string;
    type: string;
    activity: {
      __typename: "Activity";
      id: string;
      name: string;
      timestamp?: string | null;
      heartRate: {
        __typename: "HeartRate";
        id: string;
        createdAt: string;
        updatedAt: string;
        heartRateActivityId: string;
        owner?: string | null;
      };
      workout?: {
        __typename: "ModelWorkoutGroupSetConnection";
        nextToken?: string | null;
      } | null;
      trainingEffect: {
        __typename: "TrainingEffect";
        anaerobic: number;
        aerobic: number;
        trainingLoad: number;
      };
      createdAt: string;
      updatedAt: string;
      activityHeartRateId: string;
      owner?: string | null;
    };
    wod: {
      __typename: "Wod";
      id: string;
      duration: {
        __typename: "Duration";
        type: string;
        value?: number | null;
      };
      groupSet: {
        __typename: "WorkoutGroupSet";
        id: string;
        type: string;
        createdAt: string;
        updatedAt: string;
        activityWorkoutId: string;
        workoutGroupSetWodId: string;
        owner?: string | null;
      };
      createdAt: string;
      updatedAt: string;
      wodGroupSetId: string;
      owner?: string | null;
    };
    rounds: Array<{
      __typename: "Round";
      index: number;
    }>;
    createdAt: string;
    updatedAt: string;
    activityWorkoutId: string;
    workoutGroupSetWodId: string;
    owner?: string | null;
  } | null;
};

export type GetGarminActivitiesQuery = {
  getGarminActivities?: Array<{
    __typename: "GarminActivity";
    id: string;
    workoutId?: number | null;
    name: string;
    description?: string | null;
    startTime: string;
    duration: number;
    type: string;
    calories: number;
    averageHR: number;
    maxHR: number;
  } | null> | null;
};

export type GetActivityQueryVariables = {
  id: string;
};

export type GetActivityQuery = {
  getActivity?: {
    __typename: "Activity";
    id: string;
    name: string;
    timestamp?: string | null;
    heartRate: {
      __typename: "HeartRate";
      id: string;
      activity: {
        __typename: "Activity";
        id: string;
        name: string;
        timestamp?: string | null;
        createdAt: string;
        updatedAt: string;
        activityHeartRateId: string;
        owner?: string | null;
      };
      data: Array<{
        __typename: "HeartRateData";
        time: number;
        timestamp: string;
        value: number;
      }>;
      createdAt: string;
      updatedAt: string;
      heartRateActivityId: string;
      owner?: string | null;
    };
    workout?: {
      __typename: "ModelWorkoutGroupSetConnection";
      items: Array<{
        __typename: "WorkoutGroupSet";
        id: string;
        type: string;
        createdAt: string;
        updatedAt: string;
        activityWorkoutId: string;
        workoutGroupSetWodId: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    trainingEffect: {
      __typename: "TrainingEffect";
      anaerobic: number;
      aerobic: number;
      trainingLoad: number;
    };
    createdAt: string;
    updatedAt: string;
    activityHeartRateId: string;
    owner?: string | null;
  } | null;
};

export type ListActivitiesQueryVariables = {
  id?: string | null;
  filter?: ModelActivityFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: ModelSortDirection | null;
};

export type ListActivitiesQuery = {
  listActivities?: {
    __typename: "ModelActivityConnection";
    items: Array<{
      __typename: "Activity";
      id: string;
      name: string;
      timestamp?: string | null;
      heartRate: {
        __typename: "HeartRate";
        id: string;
        createdAt: string;
        updatedAt: string;
        heartRateActivityId: string;
        owner?: string | null;
      };
      workout?: {
        __typename: "ModelWorkoutGroupSetConnection";
        nextToken?: string | null;
      } | null;
      trainingEffect: {
        __typename: "TrainingEffect";
        anaerobic: number;
        aerobic: number;
        trainingLoad: number;
      };
      createdAt: string;
      updatedAt: string;
      activityHeartRateId: string;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetHeartRateQueryVariables = {
  id: string;
};

export type GetHeartRateQuery = {
  getHeartRate?: {
    __typename: "HeartRate";
    id: string;
    activity: {
      __typename: "Activity";
      id: string;
      name: string;
      timestamp?: string | null;
      heartRate: {
        __typename: "HeartRate";
        id: string;
        createdAt: string;
        updatedAt: string;
        heartRateActivityId: string;
        owner?: string | null;
      };
      workout?: {
        __typename: "ModelWorkoutGroupSetConnection";
        nextToken?: string | null;
      } | null;
      trainingEffect: {
        __typename: "TrainingEffect";
        anaerobic: number;
        aerobic: number;
        trainingLoad: number;
      };
      createdAt: string;
      updatedAt: string;
      activityHeartRateId: string;
      owner?: string | null;
    };
    data: Array<{
      __typename: "HeartRateData";
      time: number;
      timestamp: string;
      value: number;
    }>;
    createdAt: string;
    updatedAt: string;
    heartRateActivityId: string;
    owner?: string | null;
  } | null;
};

export type ListHeartRatesQueryVariables = {
  id?: string | null;
  filter?: ModelHeartRateFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: ModelSortDirection | null;
};

export type ListHeartRatesQuery = {
  listHeartRates?: {
    __typename: "ModelHeartRateConnection";
    items: Array<{
      __typename: "HeartRate";
      id: string;
      activity: {
        __typename: "Activity";
        id: string;
        name: string;
        timestamp?: string | null;
        createdAt: string;
        updatedAt: string;
        activityHeartRateId: string;
        owner?: string | null;
      };
      data: Array<{
        __typename: "HeartRateData";
        time: number;
        timestamp: string;
        value: number;
      }>;
      createdAt: string;
      updatedAt: string;
      heartRateActivityId: string;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetWodQueryVariables = {
  id: string;
};

export type GetWodQuery = {
  getWod?: {
    __typename: "Wod";
    id: string;
    duration: {
      __typename: "Duration";
      type: string;
      value?: number | null;
    };
    steps: Array<{
      __typename: "WodStep";
      exercise?: {
        __typename: "WodExercise";
        index: number;
      } | null;
      round?: {
        __typename: "WodRound";
        index: number;
      } | null;
    }>;
    groupSet: {
      __typename: "WorkoutGroupSet";
      id: string;
      type: string;
      activity: {
        __typename: "Activity";
        id: string;
        name: string;
        timestamp?: string | null;
        createdAt: string;
        updatedAt: string;
        activityHeartRateId: string;
        owner?: string | null;
      };
      wod: {
        __typename: "Wod";
        id: string;
        createdAt: string;
        updatedAt: string;
        wodGroupSetId: string;
        owner?: string | null;
      };
      rounds: Array<{
        __typename: "Round";
        index: number;
      }>;
      createdAt: string;
      updatedAt: string;
      activityWorkoutId: string;
      workoutGroupSetWodId: string;
      owner?: string | null;
    };
    createdAt: string;
    updatedAt: string;
    wodGroupSetId: string;
    owner?: string | null;
  } | null;
};

export type ListWodsQueryVariables = {
  id?: string | null;
  filter?: ModelWodFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: ModelSortDirection | null;
};

export type ListWodsQuery = {
  listWods?: {
    __typename: "ModelWodConnection";
    items: Array<{
      __typename: "Wod";
      id: string;
      duration: {
        __typename: "Duration";
        type: string;
        value?: number | null;
      };
      groupSet: {
        __typename: "WorkoutGroupSet";
        id: string;
        type: string;
        createdAt: string;
        updatedAt: string;
        activityWorkoutId: string;
        workoutGroupSetWodId: string;
        owner?: string | null;
      };
      createdAt: string;
      updatedAt: string;
      wodGroupSetId: string;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetWorkoutGroupSetQueryVariables = {
  id: string;
};

export type GetWorkoutGroupSetQuery = {
  getWorkoutGroupSet?: {
    __typename: "WorkoutGroupSet";
    id: string;
    type: string;
    activity: {
      __typename: "Activity";
      id: string;
      name: string;
      timestamp?: string | null;
      heartRate: {
        __typename: "HeartRate";
        id: string;
        createdAt: string;
        updatedAt: string;
        heartRateActivityId: string;
        owner?: string | null;
      };
      workout?: {
        __typename: "ModelWorkoutGroupSetConnection";
        nextToken?: string | null;
      } | null;
      trainingEffect: {
        __typename: "TrainingEffect";
        anaerobic: number;
        aerobic: number;
        trainingLoad: number;
      };
      createdAt: string;
      updatedAt: string;
      activityHeartRateId: string;
      owner?: string | null;
    };
    wod: {
      __typename: "Wod";
      id: string;
      duration: {
        __typename: "Duration";
        type: string;
        value?: number | null;
      };
      groupSet: {
        __typename: "WorkoutGroupSet";
        id: string;
        type: string;
        createdAt: string;
        updatedAt: string;
        activityWorkoutId: string;
        workoutGroupSetWodId: string;
        owner?: string | null;
      };
      createdAt: string;
      updatedAt: string;
      wodGroupSetId: string;
      owner?: string | null;
    };
    rounds: Array<{
      __typename: "Round";
      index: number;
    }>;
    createdAt: string;
    updatedAt: string;
    activityWorkoutId: string;
    workoutGroupSetWodId: string;
    owner?: string | null;
  } | null;
};

export type ListWorkoutGroupSetsQueryVariables = {
  id?: string | null;
  filter?: ModelWorkoutGroupSetFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: ModelSortDirection | null;
};

export type ListWorkoutGroupSetsQuery = {
  listWorkoutGroupSets?: {
    __typename: "ModelWorkoutGroupSetConnection";
    items: Array<{
      __typename: "WorkoutGroupSet";
      id: string;
      type: string;
      activity: {
        __typename: "Activity";
        id: string;
        name: string;
        timestamp?: string | null;
        createdAt: string;
        updatedAt: string;
        activityHeartRateId: string;
        owner?: string | null;
      };
      wod: {
        __typename: "Wod";
        id: string;
        createdAt: string;
        updatedAt: string;
        wodGroupSetId: string;
        owner?: string | null;
      };
      rounds: Array<{
        __typename: "Round";
        index: number;
      }>;
      createdAt: string;
      updatedAt: string;
      activityWorkoutId: string;
      workoutGroupSetWodId: string;
      owner?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type OnCreateActivitySubscriptionVariables = {
  owner?: string | null;
};

export type OnCreateActivitySubscription = {
  onCreateActivity?: {
    __typename: "Activity";
    id: string;
    name: string;
    timestamp?: string | null;
    heartRate: {
      __typename: "HeartRate";
      id: string;
      activity: {
        __typename: "Activity";
        id: string;
        name: string;
        timestamp?: string | null;
        createdAt: string;
        updatedAt: string;
        activityHeartRateId: string;
        owner?: string | null;
      };
      data: Array<{
        __typename: "HeartRateData";
        time: number;
        timestamp: string;
        value: number;
      }>;
      createdAt: string;
      updatedAt: string;
      heartRateActivityId: string;
      owner?: string | null;
    };
    workout?: {
      __typename: "ModelWorkoutGroupSetConnection";
      items: Array<{
        __typename: "WorkoutGroupSet";
        id: string;
        type: string;
        createdAt: string;
        updatedAt: string;
        activityWorkoutId: string;
        workoutGroupSetWodId: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    trainingEffect: {
      __typename: "TrainingEffect";
      anaerobic: number;
      aerobic: number;
      trainingLoad: number;
    };
    createdAt: string;
    updatedAt: string;
    activityHeartRateId: string;
    owner?: string | null;
  } | null;
};

export type OnUpdateActivitySubscriptionVariables = {
  owner?: string | null;
};

export type OnUpdateActivitySubscription = {
  onUpdateActivity?: {
    __typename: "Activity";
    id: string;
    name: string;
    timestamp?: string | null;
    heartRate: {
      __typename: "HeartRate";
      id: string;
      activity: {
        __typename: "Activity";
        id: string;
        name: string;
        timestamp?: string | null;
        createdAt: string;
        updatedAt: string;
        activityHeartRateId: string;
        owner?: string | null;
      };
      data: Array<{
        __typename: "HeartRateData";
        time: number;
        timestamp: string;
        value: number;
      }>;
      createdAt: string;
      updatedAt: string;
      heartRateActivityId: string;
      owner?: string | null;
    };
    workout?: {
      __typename: "ModelWorkoutGroupSetConnection";
      items: Array<{
        __typename: "WorkoutGroupSet";
        id: string;
        type: string;
        createdAt: string;
        updatedAt: string;
        activityWorkoutId: string;
        workoutGroupSetWodId: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    trainingEffect: {
      __typename: "TrainingEffect";
      anaerobic: number;
      aerobic: number;
      trainingLoad: number;
    };
    createdAt: string;
    updatedAt: string;
    activityHeartRateId: string;
    owner?: string | null;
  } | null;
};

export type OnDeleteActivitySubscriptionVariables = {
  owner?: string | null;
};

export type OnDeleteActivitySubscription = {
  onDeleteActivity?: {
    __typename: "Activity";
    id: string;
    name: string;
    timestamp?: string | null;
    heartRate: {
      __typename: "HeartRate";
      id: string;
      activity: {
        __typename: "Activity";
        id: string;
        name: string;
        timestamp?: string | null;
        createdAt: string;
        updatedAt: string;
        activityHeartRateId: string;
        owner?: string | null;
      };
      data: Array<{
        __typename: "HeartRateData";
        time: number;
        timestamp: string;
        value: number;
      }>;
      createdAt: string;
      updatedAt: string;
      heartRateActivityId: string;
      owner?: string | null;
    };
    workout?: {
      __typename: "ModelWorkoutGroupSetConnection";
      items: Array<{
        __typename: "WorkoutGroupSet";
        id: string;
        type: string;
        createdAt: string;
        updatedAt: string;
        activityWorkoutId: string;
        workoutGroupSetWodId: string;
        owner?: string | null;
      } | null>;
      nextToken?: string | null;
    } | null;
    trainingEffect: {
      __typename: "TrainingEffect";
      anaerobic: number;
      aerobic: number;
      trainingLoad: number;
    };
    createdAt: string;
    updatedAt: string;
    activityHeartRateId: string;
    owner?: string | null;
  } | null;
};

export type OnCreateHeartRateSubscriptionVariables = {
  owner?: string | null;
};

export type OnCreateHeartRateSubscription = {
  onCreateHeartRate?: {
    __typename: "HeartRate";
    id: string;
    activity: {
      __typename: "Activity";
      id: string;
      name: string;
      timestamp?: string | null;
      heartRate: {
        __typename: "HeartRate";
        id: string;
        createdAt: string;
        updatedAt: string;
        heartRateActivityId: string;
        owner?: string | null;
      };
      workout?: {
        __typename: "ModelWorkoutGroupSetConnection";
        nextToken?: string | null;
      } | null;
      trainingEffect: {
        __typename: "TrainingEffect";
        anaerobic: number;
        aerobic: number;
        trainingLoad: number;
      };
      createdAt: string;
      updatedAt: string;
      activityHeartRateId: string;
      owner?: string | null;
    };
    data: Array<{
      __typename: "HeartRateData";
      time: number;
      timestamp: string;
      value: number;
    }>;
    createdAt: string;
    updatedAt: string;
    heartRateActivityId: string;
    owner?: string | null;
  } | null;
};

export type OnUpdateHeartRateSubscriptionVariables = {
  owner?: string | null;
};

export type OnUpdateHeartRateSubscription = {
  onUpdateHeartRate?: {
    __typename: "HeartRate";
    id: string;
    activity: {
      __typename: "Activity";
      id: string;
      name: string;
      timestamp?: string | null;
      heartRate: {
        __typename: "HeartRate";
        id: string;
        createdAt: string;
        updatedAt: string;
        heartRateActivityId: string;
        owner?: string | null;
      };
      workout?: {
        __typename: "ModelWorkoutGroupSetConnection";
        nextToken?: string | null;
      } | null;
      trainingEffect: {
        __typename: "TrainingEffect";
        anaerobic: number;
        aerobic: number;
        trainingLoad: number;
      };
      createdAt: string;
      updatedAt: string;
      activityHeartRateId: string;
      owner?: string | null;
    };
    data: Array<{
      __typename: "HeartRateData";
      time: number;
      timestamp: string;
      value: number;
    }>;
    createdAt: string;
    updatedAt: string;
    heartRateActivityId: string;
    owner?: string | null;
  } | null;
};

export type OnDeleteHeartRateSubscriptionVariables = {
  owner?: string | null;
};

export type OnDeleteHeartRateSubscription = {
  onDeleteHeartRate?: {
    __typename: "HeartRate";
    id: string;
    activity: {
      __typename: "Activity";
      id: string;
      name: string;
      timestamp?: string | null;
      heartRate: {
        __typename: "HeartRate";
        id: string;
        createdAt: string;
        updatedAt: string;
        heartRateActivityId: string;
        owner?: string | null;
      };
      workout?: {
        __typename: "ModelWorkoutGroupSetConnection";
        nextToken?: string | null;
      } | null;
      trainingEffect: {
        __typename: "TrainingEffect";
        anaerobic: number;
        aerobic: number;
        trainingLoad: number;
      };
      createdAt: string;
      updatedAt: string;
      activityHeartRateId: string;
      owner?: string | null;
    };
    data: Array<{
      __typename: "HeartRateData";
      time: number;
      timestamp: string;
      value: number;
    }>;
    createdAt: string;
    updatedAt: string;
    heartRateActivityId: string;
    owner?: string | null;
  } | null;
};

export type OnCreateWodSubscriptionVariables = {
  owner?: string | null;
};

export type OnCreateWodSubscription = {
  onCreateWod?: {
    __typename: "Wod";
    id: string;
    duration: {
      __typename: "Duration";
      type: string;
      value?: number | null;
    };
    steps: Array<{
      __typename: "WodStep";
      exercise?: {
        __typename: "WodExercise";
        index: number;
      } | null;
      round?: {
        __typename: "WodRound";
        index: number;
      } | null;
    }>;
    groupSet: {
      __typename: "WorkoutGroupSet";
      id: string;
      type: string;
      activity: {
        __typename: "Activity";
        id: string;
        name: string;
        timestamp?: string | null;
        createdAt: string;
        updatedAt: string;
        activityHeartRateId: string;
        owner?: string | null;
      };
      wod: {
        __typename: "Wod";
        id: string;
        createdAt: string;
        updatedAt: string;
        wodGroupSetId: string;
        owner?: string | null;
      };
      rounds: Array<{
        __typename: "Round";
        index: number;
      }>;
      createdAt: string;
      updatedAt: string;
      activityWorkoutId: string;
      workoutGroupSetWodId: string;
      owner?: string | null;
    };
    createdAt: string;
    updatedAt: string;
    wodGroupSetId: string;
    owner?: string | null;
  } | null;
};

export type OnUpdateWodSubscriptionVariables = {
  owner?: string | null;
};

export type OnUpdateWodSubscription = {
  onUpdateWod?: {
    __typename: "Wod";
    id: string;
    duration: {
      __typename: "Duration";
      type: string;
      value?: number | null;
    };
    steps: Array<{
      __typename: "WodStep";
      exercise?: {
        __typename: "WodExercise";
        index: number;
      } | null;
      round?: {
        __typename: "WodRound";
        index: number;
      } | null;
    }>;
    groupSet: {
      __typename: "WorkoutGroupSet";
      id: string;
      type: string;
      activity: {
        __typename: "Activity";
        id: string;
        name: string;
        timestamp?: string | null;
        createdAt: string;
        updatedAt: string;
        activityHeartRateId: string;
        owner?: string | null;
      };
      wod: {
        __typename: "Wod";
        id: string;
        createdAt: string;
        updatedAt: string;
        wodGroupSetId: string;
        owner?: string | null;
      };
      rounds: Array<{
        __typename: "Round";
        index: number;
      }>;
      createdAt: string;
      updatedAt: string;
      activityWorkoutId: string;
      workoutGroupSetWodId: string;
      owner?: string | null;
    };
    createdAt: string;
    updatedAt: string;
    wodGroupSetId: string;
    owner?: string | null;
  } | null;
};

export type OnDeleteWodSubscriptionVariables = {
  owner?: string | null;
};

export type OnDeleteWodSubscription = {
  onDeleteWod?: {
    __typename: "Wod";
    id: string;
    duration: {
      __typename: "Duration";
      type: string;
      value?: number | null;
    };
    steps: Array<{
      __typename: "WodStep";
      exercise?: {
        __typename: "WodExercise";
        index: number;
      } | null;
      round?: {
        __typename: "WodRound";
        index: number;
      } | null;
    }>;
    groupSet: {
      __typename: "WorkoutGroupSet";
      id: string;
      type: string;
      activity: {
        __typename: "Activity";
        id: string;
        name: string;
        timestamp?: string | null;
        createdAt: string;
        updatedAt: string;
        activityHeartRateId: string;
        owner?: string | null;
      };
      wod: {
        __typename: "Wod";
        id: string;
        createdAt: string;
        updatedAt: string;
        wodGroupSetId: string;
        owner?: string | null;
      };
      rounds: Array<{
        __typename: "Round";
        index: number;
      }>;
      createdAt: string;
      updatedAt: string;
      activityWorkoutId: string;
      workoutGroupSetWodId: string;
      owner?: string | null;
    };
    createdAt: string;
    updatedAt: string;
    wodGroupSetId: string;
    owner?: string | null;
  } | null;
};

export type OnCreateWorkoutGroupSetSubscriptionVariables = {
  owner?: string | null;
};

export type OnCreateWorkoutGroupSetSubscription = {
  onCreateWorkoutGroupSet?: {
    __typename: "WorkoutGroupSet";
    id: string;
    type: string;
    activity: {
      __typename: "Activity";
      id: string;
      name: string;
      timestamp?: string | null;
      heartRate: {
        __typename: "HeartRate";
        id: string;
        createdAt: string;
        updatedAt: string;
        heartRateActivityId: string;
        owner?: string | null;
      };
      workout?: {
        __typename: "ModelWorkoutGroupSetConnection";
        nextToken?: string | null;
      } | null;
      trainingEffect: {
        __typename: "TrainingEffect";
        anaerobic: number;
        aerobic: number;
        trainingLoad: number;
      };
      createdAt: string;
      updatedAt: string;
      activityHeartRateId: string;
      owner?: string | null;
    };
    wod: {
      __typename: "Wod";
      id: string;
      duration: {
        __typename: "Duration";
        type: string;
        value?: number | null;
      };
      groupSet: {
        __typename: "WorkoutGroupSet";
        id: string;
        type: string;
        createdAt: string;
        updatedAt: string;
        activityWorkoutId: string;
        workoutGroupSetWodId: string;
        owner?: string | null;
      };
      createdAt: string;
      updatedAt: string;
      wodGroupSetId: string;
      owner?: string | null;
    };
    rounds: Array<{
      __typename: "Round";
      index: number;
    }>;
    createdAt: string;
    updatedAt: string;
    activityWorkoutId: string;
    workoutGroupSetWodId: string;
    owner?: string | null;
  } | null;
};

export type OnUpdateWorkoutGroupSetSubscriptionVariables = {
  owner?: string | null;
};

export type OnUpdateWorkoutGroupSetSubscription = {
  onUpdateWorkoutGroupSet?: {
    __typename: "WorkoutGroupSet";
    id: string;
    type: string;
    activity: {
      __typename: "Activity";
      id: string;
      name: string;
      timestamp?: string | null;
      heartRate: {
        __typename: "HeartRate";
        id: string;
        createdAt: string;
        updatedAt: string;
        heartRateActivityId: string;
        owner?: string | null;
      };
      workout?: {
        __typename: "ModelWorkoutGroupSetConnection";
        nextToken?: string | null;
      } | null;
      trainingEffect: {
        __typename: "TrainingEffect";
        anaerobic: number;
        aerobic: number;
        trainingLoad: number;
      };
      createdAt: string;
      updatedAt: string;
      activityHeartRateId: string;
      owner?: string | null;
    };
    wod: {
      __typename: "Wod";
      id: string;
      duration: {
        __typename: "Duration";
        type: string;
        value?: number | null;
      };
      groupSet: {
        __typename: "WorkoutGroupSet";
        id: string;
        type: string;
        createdAt: string;
        updatedAt: string;
        activityWorkoutId: string;
        workoutGroupSetWodId: string;
        owner?: string | null;
      };
      createdAt: string;
      updatedAt: string;
      wodGroupSetId: string;
      owner?: string | null;
    };
    rounds: Array<{
      __typename: "Round";
      index: number;
    }>;
    createdAt: string;
    updatedAt: string;
    activityWorkoutId: string;
    workoutGroupSetWodId: string;
    owner?: string | null;
  } | null;
};

export type OnDeleteWorkoutGroupSetSubscriptionVariables = {
  owner?: string | null;
};

export type OnDeleteWorkoutGroupSetSubscription = {
  onDeleteWorkoutGroupSet?: {
    __typename: "WorkoutGroupSet";
    id: string;
    type: string;
    activity: {
      __typename: "Activity";
      id: string;
      name: string;
      timestamp?: string | null;
      heartRate: {
        __typename: "HeartRate";
        id: string;
        createdAt: string;
        updatedAt: string;
        heartRateActivityId: string;
        owner?: string | null;
      };
      workout?: {
        __typename: "ModelWorkoutGroupSetConnection";
        nextToken?: string | null;
      } | null;
      trainingEffect: {
        __typename: "TrainingEffect";
        anaerobic: number;
        aerobic: number;
        trainingLoad: number;
      };
      createdAt: string;
      updatedAt: string;
      activityHeartRateId: string;
      owner?: string | null;
    };
    wod: {
      __typename: "Wod";
      id: string;
      duration: {
        __typename: "Duration";
        type: string;
        value?: number | null;
      };
      groupSet: {
        __typename: "WorkoutGroupSet";
        id: string;
        type: string;
        createdAt: string;
        updatedAt: string;
        activityWorkoutId: string;
        workoutGroupSetWodId: string;
        owner?: string | null;
      };
      createdAt: string;
      updatedAt: string;
      wodGroupSetId: string;
      owner?: string | null;
    };
    rounds: Array<{
      __typename: "Round";
      index: number;
    }>;
    createdAt: string;
    updatedAt: string;
    activityWorkoutId: string;
    workoutGroupSetWodId: string;
    owner?: string | null;
  } | null;
};
