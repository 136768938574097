import React from "react";
import { AppBar, Typography, Toolbar, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

interface Props {
  onPressDrawerToggle: () => void;
}

export function NavBar({ onPressDrawerToggle }: Props) {
  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton color="inherit" aria-label="open drawer" onClick={onPressDrawerToggle}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap>
          Garmin Workouts
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
