import React from "react";
import { Line } from "react-chartjs-2";
import { useMemo } from "react";
import { green, lime, red } from "@mui/material/colors";
import { formatSeconds, WorkoutTimeFormat } from "common/utils";
import { avgHeartRate, maxHeartRate } from "features/workouts";
import { HeartRate } from "app/api-base";

export interface Annotation {
  id: string;
  borderColor: string;
  xMin: number;
  xMax: number;
  content?: string;
  position?: string;
}

interface Props {
  heartRate: HeartRate;
  annotations: Annotation[];
  avgHR: boolean;
  maxHR: boolean;
}

export function HeartRateChart({ heartRate, annotations, avgHR, maxHR }: Props) {
  const avg = useMemo(() => avgHeartRate(heartRate).toFixed(0), [heartRate]);
  const max = useMemo(() => maxHeartRate(heartRate).toFixed(0), [heartRate]);
  const labels = useMemo(() => heartRate.data.map((heartRate) => heartRate.time), [heartRate]);
  const dataHR = useMemo(() => heartRate.data.map((heartRate) => heartRate.value), [heartRate]);
  const data = {
    labels,
    datasets: [
      {
        label: "Heart rate",
        data: dataHR,
        borderColor: red[200],
        backgroundColor: red[400],
      },
    ],
  };

  const chartAnnotations: Record<string, unknown> = {};
  annotations.forEach((set: Annotation) => {
    chartAnnotations[set.id] = {
      scaleID: set.id,
      type: "line",
      mode: "vertical",
      xMin: set.xMin,
      xMax: set.xMax,
      borderWidth: 1,
      borderColor: set.borderColor,
      label: set.content && {
        content: set.content,
        enabled: true,
        position: set.position,
      },
    };
  });

  if (avgHR) {
    chartAnnotations["avg"] = {
      type: "line",
      mode: "vertical",
      scaleID: "avg",
      yMin: avg,
      yMax: avg,
      borderWidth: 1,
      borderColor: lime[400],
      label: {
        content: `${avg} avg HR`,
        enabled: true,
        position: "start",
      },
    };
  }

  if (maxHR) {
    chartAnnotations["maxHR"] = {
      type: "line",
      mode: "vertical",
      scaleID: "maxHR",
      yMin: max,
      yMax: max,
      borderWidth: 1,
      borderColor: green[400],
      label: {
        content: `${max} max HR`,
        enabled: true,
        position: "start",
      },
    };
  }

  const options: any = {
    scales: {
      y: {
        type: "linear",
        grace: "10%",
      },
      x: {
        type: "linear",
        ticks: {
          callback: (value: number) => formatSeconds(value, WorkoutTimeFormat),
        },
      },
    },
    plugins: {
      autocolors: false,
      annotation: {
        annotations: chartAnnotations,
      },
      tooltip: {
        callbacks: {
          title: (item: any) => formatSeconds(item[0].parsed.x, WorkoutTimeFormat),
        },
      },
    },
  };

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
}
