import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";
import { NavBar } from "./nav-bar";
import { NavDrawer } from "./nav-drawer";
import { GarminNavigator } from "features/garmin/garmin-navigator";
import { WorkoutDetails } from "features/workouts/pages";
import { Home } from "app/pages";

export const RootNavigator = () => {
  const [open, setOpen] = useState(false);

  function handlePressDrawerToggle() {
    setOpen((open) => !open);
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
      <NavBar onPressDrawerToggle={handlePressDrawerToggle} />
      <NavDrawer open={open} onClose={() => setOpen(false)} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/workouts/:id" element={<WorkoutDetails />} />
        <Route path="/garmin/*" element={<GarminNavigator />} />
      </Routes>
    </Box>
  );
};
