import React from "react";
import { Paper, TableRow, TableCell, Button } from "@mui/material";
import { EnhancedTable, HeadCell } from "common/components";
import { GarminActivity } from "features/garmin/garmin.types";

const columns: HeadCell[] = [
  {
    id: "id",
    numeric: true,
    disablePadding: false,
    label: "ID",
    sort: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    sort: true,
  },
  {
    id: "startTime",
    numeric: false,
    disablePadding: false,
    label: "Date",
    sort: true,
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Action",
    sort: false,
  },
];

interface Props {
  activities: GarminActivity[];
  loading: boolean;
  onClickImportActivity: (activity: GarminActivity) => void;
}

export function GarminActivitiesList({ activities, loading, onClickImportActivity }: Props) {
  return (
    <Paper>
      <EnhancedTable
        search
        data={activities}
        columns={columns}
        loading={loading}
        defaultSort="startDate"
      >
        {(activities) =>
          activities.map((row, index) => (
            <TableRow hover role="row" tabIndex={index} key={index}>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.startTime}</TableCell>
              <TableCell>
                <Button onClick={() => onClickImportActivity(row)}>Import</Button>
              </TableCell>
            </TableRow>
          ))
        }
      </EnhancedTable>
    </Paper>
  );
}
