import React from "react";
import {
  getWorkoutDisplayStartTime,
  avgHeartRate,
  maxHeartRate,
  getDisplayExercise,
} from "features/workouts/workout.types";
import { TimeFormat } from "common/utils";
import { Activity } from "app/api-base";

interface Props {
  activity: Activity;
}

export function WorkoutStats({ activity }: Props) {
  return (
    <div>
      <ul>
        <li>Start time: {getWorkoutDisplayStartTime(activity, TimeFormat)}</li>
        <li>
          <span>Training effect</span>
          <ul>
            <li>Aerobic: {activity.trainingEffect.aerobic}</li>
            <li>Anaerobic: {activity.trainingEffect.anaerobic}</li>
            <li>Training load: {activity.trainingEffect.trainingLoad}</li>
          </ul>
        </li>
        <li>
          <span>Heart rate</span>
          <ul>
            <li>Avg: {avgHeartRate(activity.heartRate)}</li>
            <li>Max: {maxHeartRate(activity.heartRate)}</li>
          </ul>
        </li>
      </ul>
      <div>
        <h5>Sets</h5>
        <ul>
          {activity.workout?.items.map(
            (groupSet, i) =>
              groupSet && (
                <li key={i}>
                  <ul>
                    <b>Group set {i}</b>
                    {groupSet.rounds.map((round, j) => (
                      <li key={j}>
                        <i>Round {j}</i>
                        <ul>
                          {round.sets.map(({ set, round }, k) => {
                            if (set) {
                              return (
                                <li key={k}>
                                  {getDisplayExercise(set.exercise)} (duration: {set.duration}/
                                  weight: {set.weight})
                                </li>
                              );
                            }
                            if (round) {
                              return (
                                <li key={k}>
                                  <span>Round {round.index}</span>
                                  <ul>
                                    {round.sets.map(
                                      ({ set }, m) =>
                                        set && (
                                          <li key={m}>
                                            {getDisplayExercise(set.exercise)} (duration:{" "}
                                            {set.duration}/ weight: {set.weight})
                                          </li>
                                        ),
                                    )}
                                  </ul>
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </li>
              ),
          )}
        </ul>
      </div>
    </div>
  );
}
