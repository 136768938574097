import React from "react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import {
  roundInnerRoundsInfo,
  getWorkoutGroupSetExercises,
  workoutGroupSetInnerRoundsInfo,
} from "features/workouts/workout.types";
import { formatSeconds, WorkoutTimeFormat } from "common/utils";
import { DisplayValueType, SingleInnerRoundWidth } from "./group-set-data-table-data";
import { WorkoutGroupSet, Set, Round } from "app/api-base";

interface SetTableCellProps {
  set: Set | null | undefined;
  displayValue: DisplayValueType;
}

function SetTableCell({ set, displayValue }: SetTableCellProps) {
  if (!set) {
    return <TableCell />;
  }

  const values: Record<DisplayValueType, string | number> = {
    duration: formatSeconds(set.duration, WorkoutTimeFormat),
    repetitionCount: set.repetitionCount,
  };
  return <TableCell>{values[displayValue]}</TableCell>;
}

interface Props {
  groupSet: WorkoutGroupSet;
  displayValue: DisplayValueType;
}

export function GroupSetDataTableBody({ groupSet, displayValue }: Props) {
  const exerciseGroups = getWorkoutGroupSetExercises(groupSet);
  const info = workoutGroupSetInnerRoundsInfo(groupSet);

  return (
    <TableBody>
      {exerciseGroups.map((grupExercise, i) => (
        <TableRow key={i}>
          {info.singleRound && i === 0 && (
            <TableCell width={SingleInnerRoundWidth} rowSpan={info.numExercises}>
              Active
            </TableCell>
          )}
          {info.singleRound && i >= info.numExercises && <TableCell>Rest</TableCell>}
          <TableCell>{grupExercise.exercises.map((e) => e.name).join("/")}</TableCell>
          {groupSet.rounds.map((round, index) => {
            const roundsInfo = roundInnerRoundsInfo(round);
            if (roundsInfo.total < 2) {
              if (grupExercise.innerIndex != undefined) {
                const set =
                  round.sets[grupExercise.index]?.round?.sets[grupExercise.innerIndex]?.set;
                return <SetTableCell key={index} set={set} displayValue={displayValue} />;
              } else {
                const { set } = round.sets[grupExercise.index];
                return <SetTableCell key={index} set={set} displayValue={displayValue} />;
              }
            }

            return round.sets
              .map((set) => set.round)
              .filter(Boolean)
              .map((innerRound, i) => {
                return (
                  <SetTableCell
                    key={i}
                    set={innerRound?.sets[i]?.set}
                    displayValue={displayValue}
                  />
                );
              });
          })}
        </TableRow>
      ))}
    </TableBody>
  );
}
