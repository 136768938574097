import React from "react";
import { Box } from "@mui/material";
import { getWorkoutGroupSetHeartRate } from "features/workouts";
import { GroupSetDetails } from "common/components";
import { Activity } from "app/api-base";

interface Props {
  activity: Activity;
}

export function WorkoutSets({ activity }: Props) {
  return (
    <>
      {activity.workout?.items.map((group) => {
        return (
          group && (
            <Box marginY={3} key={group.id}>
              <GroupSetDetails
                title={group.type}
                groupSet={group}
                heartRate={getWorkoutGroupSetHeartRate(activity, group)}
              />
            </Box>
          )
        );
      })}
    </>
  );
}
