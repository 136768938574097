import { createApi } from "@reduxjs/toolkit/query/react";
import {
  Activity,
  amplifyGraphqlBaseQuery,
  createQuery,
  getActivity,
  GetActivityQueryVariables,
  listActivities,
} from "app/api-base";

export const workoutsApi = createApi({
  reducerPath: "workoutsApi",
  baseQuery: amplifyGraphqlBaseQuery(),
  endpoints: (builder) => ({
    getWorkouts: builder.query<Activity[], void>({
      query: () =>
        createQuery({
          query: listActivities,
          getDataFromResponse: (data) => data.listActivities.items,
        }),
    }),
    getWorkout: builder.query<Activity, GetActivityQueryVariables>({
      query: ({ id }) =>
        createQuery<void, GetActivityQueryVariables>({
          query: getActivity,
          variables: {
            id,
          },
          getDataFromResponse: (data) => data.getActivity,
        }),
    }),
  }),
});

export const { useGetWorkoutsQuery, useGetWorkoutQuery } = workoutsApi;
