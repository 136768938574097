import { createTheme as muiCreateTheme, LinkProps } from "@mui/material";
import { grey } from "@mui/material/colors";
import { LinkBehaviour } from "common/components";

export function createTheme() {
  return muiCreateTheme({
    typography: {
      fontFamily: "Public Sans, sans-serif",
      h1: {
        fontSize: 18,
        fontWeight: 500,
        lineHeight: 1.5,
      },
      h3: {
        fontSize: "1.875rem",
        fontWeight: 700,
        lineHeight: 1.5,
      },
      h4: {
        fontSize: "1.5rem",
        fontWeight: 700,
        lineHeight: 1.5,
      },
      h6: {
        fontSize: "1.125rem",
        fontWeight: 700,
        lineHeight: 1.5,
      },
      body1: {
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: 1.5,
      },
      body2: {
        fontSize: "0.875rem",
        fontWeight: 400,
        lineHeight: 1.5,
      },
      subtitle2: {
        fontSize: "0.875rem",
        fontWeight: 400,
        lineHeight: 1.5,
      },
      button: {
        fontSize: "0.875rem",
        fontWeight: 700,
        lineHeight: 1.5,
      },
      caption: {
        fontSize: "0.75rem",
        fontWeight: 400,
        lineHeight: 1.5,
      },
    },
    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehaviour,
        } as LinkProps,
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehaviour,
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.variant === "head" && {
              fontWeight: 600,
              backgroundColor: grey[50],
            }),
          }),
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:hover": {
              backgroundColor: grey[100],
            },
          },
        },
      },
      MuiContainer: {
        defaultProps: {
          maxWidth: "lg",
        },
      },
    },
  });
}
