import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Authenticator } from "@aws-amplify/ui-react";
import { store, persistor } from "./store";
import { setupChartJS } from "./chartjs";
import { RootNavigator } from "./root-navigator";
import { createTheme } from "./theme";
import "@aws-amplify/ui-react/styles.css";

setupChartJS();

export const App = () => {
  const theme = createTheme();

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Authenticator>
            <BrowserRouter>
              <RootNavigator />
            </BrowserRouter>
          </Authenticator>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};
