import React from "react";
import { TableCell, TableHead, TableRow } from "@mui/material";
import {
  roundInnerRoundsInfo,
  workoutGroupSetInnerRoundsInfo,
} from "features/workouts/workout.types";
import { WorkoutGroupSet } from "app/api-base";

interface Props {
  groupSet: WorkoutGroupSet;
}

export function GroupSetDataTableHeader({ groupSet }: Props) {
  const roundsInfo = workoutGroupSetInnerRoundsInfo(groupSet);

  return (
    <TableHead>
      <TableRow>
        {roundsInfo.singleRound && <TableCell />}
        <TableCell variant="head">Name</TableCell>
        {groupSet.rounds.map((round, index) => {
          const roundsInfo = roundInnerRoundsInfo(round);
          let colSpan = roundsInfo.total;
          if (roundsInfo.total === 0) {
            colSpan = 1;
          }

          return (
            <TableCell key={index} colSpan={colSpan} variant="head">
              Round {round.index}
            </TableCell>
          );
        })}
      </TableRow>
      {roundsInfo.multiRounds && (
        <TableRow>
          <TableCell />
          {groupSet.rounds.map((round) =>
            roundInnerRoundsInfo(round) ? (
              round.sets.map((_, i) => (
                <TableCell variant="head" key={i}>
                  R{i}
                </TableCell>
              ))
            ) : (
              <TableCell />
            ),
          )}
        </TableRow>
      )}
    </TableHead>
  );
}
