import React from "react";
import { Drawer, Divider, List, ListItem } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface Props {
  open: boolean;
  onClose: () => void;
}

export function NavDrawer({ open, onClose }: Props) {
  const navigate = useNavigate();

  function handleClickItem(text: string) {
    navigate(text);
  }

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <Divider />
      <List>
        <ListItem button onClick={() => handleClickItem("/")}>
          Home
        </ListItem>
        <ListItem button onClick={() => handleClickItem("/garmin")}>
          Garmin
        </ListItem>
      </List>
    </Drawer>
  );
}
