import React from "react";
import { Box, Tabs, Tab, Container } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Header } from "common/components";
import { Activity } from "app/api-base";

interface Props {
  activity: Activity;
  tabs: {
    id: string;
    text: string;
  }[];
  tabValue: number;
  onChangeTab: (index: number) => void;
}

export const WorkoutHeader = ({ activity, tabs, tabValue, onChangeTab }: Props) => {
  return (
    <Box sx={{ backgroundColor: grey[50] }}>
      <Header
        title={`Workout ${activity.name}`}
        breadcrumbs={[
          {
            id: "workouts",
            text: "Workouts",
            link: "/",
          },
          {
            id: "details",
            text: activity.name,
            link: `/workouts/${activity.id}`,
          },
        ]}
      />
      <Container>
        <Tabs value={tabValue} onChange={(_, i) => onChangeTab(i)}>
          {tabs.map((tab) => (
            <Tab key={tab.id} label={tab.text} />
          ))}
        </Tabs>
      </Container>
    </Box>
  );
};
