import React from "react";
import { Container, Box } from "@mui/material";
import { useGetWorkoutsQuery } from "features/workouts";
import { useNavigate } from "react-router-dom";
import { Header } from "common/components";
import { WorkoutsList } from "features/workouts/components";
import { Activity } from "app/api-base";

export function Home() {
  const navigate = useNavigate();
  const { currentData, isFetching } = useGetWorkoutsQuery();

  function handleClickWorkout(workout: Activity) {
    navigate(`/workouts/${workout.id}`);
  }

  function handleRequestSort(col: string, order: "asc" | "desc") {
    //TODO
  }

  return (
    <Box data-testid="home-page">
      <Header title="Workouts" />
      <Container>
        <WorkoutsList
          loading={isFetching}
          activities={currentData || []}
          defaultSort="timestamp"
          defaultOrder="asc"
          onRequestSort={handleRequestSort}
          onClickActivity={handleClickWorkout}
        />
      </Container>
    </Box>
  );
}
