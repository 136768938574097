import { configureStore, ThunkAction, Action, combineReducers } from "@reduxjs/toolkit";
import { garminApi } from "features/garmin";
import { workoutsApi } from "features/workouts";
import { authReducer } from "features/auth";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const rootReducer = combineReducers({
  [workoutsApi.reducerPath]: workoutsApi.reducer,
  [garminApi.reducerPath]: garminApi.reducer,
  auth: authReducer,
});

const persistedReducer = persistReducer(
  {
    key: "root",
    version: 1,
    storage,
  },
  rootReducer,
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(workoutsApi.middleware).concat(garminApi.middleware),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
