import React from "react";
import { Paper, TableRow, TableCell, Stack, Chip } from "@mui/material";
import { getWorkoutDisplayStartTime } from "features/workouts/workout.types";
import { HeadCell, EnhancedRemoteTable } from "common/components";
import { DateFormat } from "common/utils";
import { Activity, WorkoutGroupSet } from "app/api-base";

const columns: HeadCell[] = [
  {
    id: "timestamp",
    numeric: false,
    disablePadding: false,
    label: "Date",
    sort: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    sort: true,
  },
  {
    id: "sets",
    numeric: false,
    disablePadding: false,
    label: "Sets",
    sort: false,
  },
];

interface Props {
  activities: Activity[];
  loading: boolean;
  defaultSort: string;
  defaultOrder: "asc" | "desc";
  onClickActivity: (activity: Activity) => void;
  onRequestSort: (col: string, order: "asc" | "desc") => void;
}

type TypeCount = { type: string; count: number };
function getWorkoutGroupedGroupTypes(groupSets: (WorkoutGroupSet | null)[]): TypeCount[] {
  const result: TypeCount[] = [];
  const TypeMatcher = (g: WorkoutGroupSet) => (r: TypeCount) => r.type === g.type;

  groupSets.forEach((groupSet) => {
    if (!groupSet) {
      return;
    }

    if (!result.some(TypeMatcher(groupSet))) {
      result.push({ type: groupSet.type, count: 0 });
    }

    const counter = result.find(TypeMatcher(groupSet));
    if (counter) {
      counter.count += 1;
    }
  });

  return result;
}

export function WorkoutsList({
  activities,
  loading,
  defaultSort,
  defaultOrder,
  onRequestSort,
  onClickActivity,
}: Props) {
  return (
    <Paper>
      <EnhancedRemoteTable
        loading={loading}
        data={activities}
        columns={columns}
        defaultSort={defaultSort}
        defaultOrder={defaultOrder}
        onRequestSort={onRequestSort}
      >
        {(activity, index) => (
          <TableRow
            key={activity.id}
            onClick={() => onClickActivity(activity)}
            role="row"
            aria-rowindex={index}
            sx={{ cursor: "pointer" }}
          >
            <TableCell scope="row" aria-rowindex={index} aria-colindex={0}>
              {getWorkoutDisplayStartTime(activity, DateFormat)}
            </TableCell>
            <TableCell scope="row" aria-rowindex={index} aria-colindex={1}>
              {activity.name}
            </TableCell>
            <TableCell scope="row" aria-rowindex={index} aria-colindex={2}>
              {activity.workout?.items && (
                <Stack direction="row" spacing={1}>
                  {getWorkoutGroupedGroupTypes(activity.workout.items).map(
                    ({ type, count }, index) => (
                      <Chip key={index} label={`${count}x${type}`} size="small" color="info" />
                    ),
                  )}
                </Stack>
              )}
            </TableCell>
          </TableRow>
        )}
      </EnhancedRemoteTable>
    </Paper>
  );
}
