import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { GarminActivitiesImportPage, GarminSignInPage } from "features/garmin/pages";

export const GarminNavigator = () => {
  const isGarminAuthenticated = useSelector<RootState>((state) => state.auth.isGarminAuthenticated);
  const navigate = useNavigate();

  useEffect(() => {
    navigate(isGarminAuthenticated ? "/garmin/activities/import" : "/garmin/sign-in");
  }, [isGarminAuthenticated]);

  return (
    <Routes>
      <Route path="/sign-in" element={<GarminSignInPage />} />
      <Route path="/activities/import" element={<GarminActivitiesImportPage />} />
    </Routes>
  );
};
