import { GRAPHQL_AUTH_MODE } from "@aws-amplify/auth";
import { SerializedError } from "@reduxjs/toolkit";
import { BaseQueryFn } from "@reduxjs/toolkit/dist/query";
import { API } from "aws-amplify";

export type ApiError = QueryError | SerializedError;

export declare type QueryError =
  | {
      status: "GRAPHQL_ERROR";
      data?: undefined;
      errors: Error[];
      error?: undefined;
    }
  | {
      status: "FETCH_ERROR";
      data?: unknown;
      error: Error | unknown;
      errors?: undefined;
    };

const EmptyResponseError: Error = {
  name: "EmptyEresponse",
  message: "There is no data or error in the response",
};

type QueryArgs = {
  query: string;
  input?: object | void;
  variables?: object | void;
  authMode?: keyof typeof GRAPHQL_AUTH_MODE;
  getDataFromResponse?: (data: any) => any;
};

export const createQuery = <T extends object | void, P extends object | void>({
  query,
  input,
  variables,
  authMode,
  getDataFromResponse,
}: {
  query: string;
  input?: T;
  variables?: P;
  authMode?: keyof typeof GRAPHQL_AUTH_MODE;
  getDataFromResponse?: (data: any) => any;
}): QueryArgs => {
  return { query, input, variables, authMode, getDataFromResponse };
};

export const amplifyGraphqlBaseQuery =
  (): BaseQueryFn<
    QueryArgs,
    unknown,
    QueryError,
    Record<string, unknown>,
    Record<string, unknown>
  > =>
  async ({
    query,
    input = {},
    variables = {},
    authMode = GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    getDataFromResponse = (data: any) => data,
  }) => {
    try {
      const response = await API.graphql<any>({
        query,
        variables: { ...variables, input },
        authMode,
      });
      if (response.data) {
        return { data: getDataFromResponse(response.data) };
      } else if (response.errors) {
        return {
          error: {
            status: "GRAPHQL_ERROR",
            errors: response.errors.map<Error>(({ message }) => ({
              name: "GraphqlError",
              message,
            })),
          },
        };
      } else {
        return { error: { status: "FETCH_ERROR", error: EmptyResponseError } };
      }
    } catch (error) {
      return {
        error: {
          status: "FETCH_ERROR",
          error,
        },
      };
    }
  };
