import moment from "moment";
import { WorkoutTimeFormat } from "common/utils";
import {
  Activity,
  Exercise,
  HeartRate,
  Round,
  Wod,
  WodExercise,
  Set,
  WorkoutGroupSet,
} from "app/api-base";

export function getRoundSets(round: Round): Set[] {
  return round.sets.map((s) => s.set).filter(Boolean) as Set[];
}

export function getRoundTime(round: Round) {
  if (round.sets.length === 0) {
    return 0;
  }
  const roundOrSet = round.sets[0];
  return roundOrSet.set?.time || 0;
}

function getRoundDuration(round: Round): number {
  return round.sets.reduce((prev, roundOrSet) => {
    if (roundOrSet.set) {
      prev += roundOrSet.set.duration;
    }
    return prev;
  }, 0);
}

export function getWodExercises(wod: Wod): WodExercise[] {
  return wod.steps.map((step) => step.exercise).filter(Boolean) as WodExercise[];
}

export function getWorkoutGroupSetTime(WorkoutGroupSet: WorkoutGroupSet) {
  if (WorkoutGroupSet.rounds.length === 0) {
    return 0;
  }
  return getRoundTime(WorkoutGroupSet.rounds[0]);
}

export function getWorkoutGroupSetEndTime(WorkoutGroupSet: WorkoutGroupSet) {
  if (WorkoutGroupSet.rounds.length === 0) {
    return 0;
  }
  const lastRound = WorkoutGroupSet.rounds[WorkoutGroupSet.rounds.length - 1];
  return getRoundTime(lastRound) + getRoundDuration(lastRound);
}

export function getWorkoutGroupSetDuration(WorkoutGroupSet: WorkoutGroupSet) {
  return WorkoutGroupSet.rounds.reduce((prev, round) => prev + getRoundDuration(round), 0);
}

export function getSetDisplayDuration(set: Set): string {
  return moment(set.duration * 1000).format(WorkoutTimeFormat);
}

export function getSetDisplayTime(set: Set): string {
  return moment(set.time * 1000).format(WorkoutTimeFormat);
}

export function getSetEndTime(s: Set): number {
  return s.time + s.duration;
}

export function getDisplayExercise(exercise: Exercise): string {
  return exercise.name || exercise.category;
}

export function isSameExercise(a: Exercise, b: Exercise): boolean {
  if (a.name && b.name) {
    const an = a.name;
    const bn = b.name;
    return an.includes(bn) || bn.includes(an);
  }
  return a.category === b.category;
}

export function getWorkoutDisplayStartTime(workout: Activity, format: string): string {
  return moment(workout.timestamp).format(format);
}

export function getWorkoutGroupSetHeartRate(
  workout: Activity,
  workoutGroupSet: WorkoutGroupSet,
): HeartRate {
  const data = workout.heartRate.data.filter(
    (data) =>
      getWorkoutGroupSetTime(workoutGroupSet) < data.time &&
      getWorkoutGroupSetEndTime(workoutGroupSet) > data.time,
  );
  return { ...workout.heartRate, data };
}

export function avgHeartRate(heartRate: HeartRate) {
  if (heartRate.data.length === 0) {
    return 0;
  }

  let avg = 0;

  heartRate.data.forEach((value) => {
    avg += value.value;
  });

  return avg / heartRate.data.length;
}

export function maxHeartRate(heartRate: HeartRate) {
  if (heartRate.data.length === 0) {
    return 0;
  }

  let max = heartRate.data[0].value;

  heartRate.data.forEach((value) => {
    if (value.value > max) {
      max = value.value;
    }
  });

  return max;
}

export interface WorkoutGroupSetExercise {
  exercises: Exercise[];
  index: number;
  innerIndex: number | undefined;
}

export function getWorkoutGroupSetExercises(
  WorkoutGroupSet: WorkoutGroupSet,
): WorkoutGroupSetExercise[] {
  const result = new Map<string, WorkoutGroupSetExercise>();

  const addSetToResult = (
    set: Set,
    key: string,
    index: number,
    innerIndex: number | undefined = undefined,
  ) => {
    if (!result.has(key)) {
      result.set(key, { exercises: [], index, innerIndex });
    }

    const item = result.get(key);
    if (item?.exercises.every((r) => r.name !== set.exercise.name)) {
      result.set(key, {
        ...item,
        exercises: [...item.exercises, set.exercise],
      });
    }
  };

  WorkoutGroupSet.rounds.forEach((round) => {
    const singleInnerRound = round.sets.filter((set) => set.round).length === 1;
    round.sets.forEach((roundOrSet, i) => {
      if (roundOrSet.set) {
        addSetToResult(roundOrSet.set, i.toString(), i);
      }
      if (roundOrSet.round) {
        roundOrSet.round.sets.forEach((roundSet, j) => {
          if (roundSet.set) {
            const key = singleInnerRound ? `${i}.${j}` : j.toString();
            addSetToResult(roundSet.set, key, i, j);
          }
        });
      }
    });
  });

  return Array.from(result.values());
}

interface InnerRoundsInfo {
  total: number;
  numExercises: number;
  multiRounds: boolean;
  singleRound: boolean;
}

export function roundInnerRoundsInfo(round: Round): InnerRoundsInfo {
  const innerRounds = round.sets.map((roundOrSet) => roundOrSet.round).filter(Boolean) as Round[];
  return {
    total: innerRounds.length,
    numExercises: innerRounds.length > 0 ? innerRounds[0].sets.length : 0,
    multiRounds: innerRounds.length > 1,
    singleRound: innerRounds.length === 1,
  };
}

export function workoutGroupSetInnerRoundsInfo(WorkoutGroupSet: WorkoutGroupSet): InnerRoundsInfo {
  return roundInnerRoundsInfo(WorkoutGroupSet.rounds[0]);
}
