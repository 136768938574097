import React from "react";
import { Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "app/store";
import { SignIn } from "common/components";
import { RootState } from "app/store";
import { garminSignIn } from "features/auth";

export const GarminSignInPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const garminSignInInProgress = useSelector<RootState, boolean>(
    (state) => state.auth.garminSignInInProgress,
  );
  const garminSignInError = useSelector<RootState, Error | undefined>(
    (state) => state.auth.garminSignInError,
  );

  function handleSubmitSignIn(email: string, password: string) {
    dispatch(garminSignIn({ email, password }));
  }

  return (
    <Container maxWidth="sm" sx={{ paddingTop: 5 }}>
      <SignIn
        title="Sign in into your Garmin account"
        subtitle="Enter your details below"
        error={garminSignInError}
        loading={garminSignInInProgress}
        onSubmitSignIn={handleSubmitSignIn}
      />
    </Container>
  );
};
