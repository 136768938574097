export const getActivity = /* GraphQL */ `
  query GetActivity($id: ID!) {
    getActivity(id: $id) {
      id
      name
      timestamp
      heartRate {
        id
        data {
          time
          timestamp
          value
        }
      }
      workout {
        items {
          id
          type
          wod {
            id
            duration {
              type
              value
            }
            steps {
              exercise {
                index
                exercise {
                  category
                  name
                }
                duration {
                  type
                  value
                }
                target {
                  type
                  value
                }
              }
              round {
                index
                duration {
                  type
                  value
                }
                exercises {
                  index
                  exercise {
                    category
                    name
                  }
                  duration {
                    type
                    value
                  }
                  target {
                    type
                    value
                  }
                }
              }
            }
          }
          rounds {
            index
            sets {
              set {
                index
                duration
                timestamp
                time
                exercise {
                  category
                  name
                }
                setType
                repetitionCount
                weight
              }
              round {
                index
                sets {
                  set {
                    index
                    duration
                    timestamp
                    time
                    exercise {
                      category
                      name
                    }
                    setType
                    repetitionCount
                    weight
                  }
                }
              }
            }
          }
        }
      }
      trainingEffect {
        anaerobic
        aerobic
        trainingLoad
      }
    }
  }
`;
export const listActivities = /* GraphQL */ `
  query ListActivities(
    $id: ID
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listActivities(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        timestamp
        heartRate {
          id
          createdAt
          updatedAt
          heartRateActivityId
          owner
        }
        workout {
          nextToken
        }
        trainingEffect {
          anaerobic
          aerobic
          trainingLoad
        }
        createdAt
        updatedAt
        activityHeartRateId
        owner
      }
      nextToken
    }
  }
`;

export const getGarminActivities = /* GraphQL */ `
  query GetGarminActivities {
    getGarminActivities {
      id
      workoutId
      name
      description
      startTime
      duration
      type
      calories
      averageHR
      maxHR
    }
  }
`;
