import React from "react";
import { Box, Typography, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import { useState } from "react";
import { getRoundTime } from "features/workouts/workout.types";
import { TabCard, TabPanel } from "common/components/tab";
import { GroupSetDataTable } from "common/components/group-set-data-table";
import { HeartRateChart, Annotation } from "common/components/heart-rate-chart";
import { HeartRate, WorkoutGroupSet } from "app/api-base";

interface Props {
  title: string;
  groupSet: WorkoutGroupSet;
  heartRate: HeartRate;
}

export function GroupSetDetails({ title, groupSet, heartRate }: Props) {
  const [roundsAnnotation, setRoundsAnnotation] = useState(true);
  const [avgHRAnnotation, setAvgHRAnnotation] = useState(true);
  const [maxHRAnnotation, setMaxHRAnnotation] = useState(true);

  const heartRateAnnotations: Annotation[] = [];
  groupSet.rounds.forEach((round, i) => {
    if (roundsAnnotation) {
      heartRateAnnotations.push({
        id: `round-${i}`,
        xMin: getRoundTime(round),
        xMax: getRoundTime(round),
        borderColor: deepOrange[400],
        content: `Round ${i + 1}`,
        position: "end",
      });
    }
  });

  return (
    <TabCard tabs={["Duration", "Repetitions", "Heart rate"]}>
      <Box padding={2}>
        <Typography variant="h4">{title}</Typography>
        <Box marginTop={1}>
          <TabPanel index={0}>
            <GroupSetDataTable groupSet={groupSet} displayValue="duration" />
          </TabPanel>
          <TabPanel index={1}>
            <GroupSetDataTable groupSet={groupSet} displayValue="repetitionCount" />
          </TabPanel>
          <TabPanel index={2}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    value={roundsAnnotation}
                    onChange={(_, checked) => setRoundsAnnotation(checked)}
                  />
                }
                label="Rounds"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    value={avgHRAnnotation}
                    onChange={(_, checked) => setAvgHRAnnotation(checked)}
                  />
                }
                label="avg HR"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    value={maxHRAnnotation}
                    onChange={(_, checked) => setMaxHRAnnotation(checked)}
                  />
                }
                label="max HR"
              />
            </FormGroup>
            <HeartRateChart
              avgHR={avgHRAnnotation}
              maxHR={maxHRAnnotation}
              heartRate={heartRate}
              annotations={heartRateAnnotations}
            />
          </TabPanel>
        </Box>
      </Box>
    </TabCard>
  );
}
