import { createApi } from "@reduxjs/toolkit/query/react";
import { GarminActivity } from "features/garmin/garmin.types";
import {
  amplifyGraphqlBaseQuery,
  createQuery,
  getGarminActivities,
  importGarminActivity,
  ImportGarminActivityMutationVariables,
} from "app/api-base";

export const garminApi = createApi({
  reducerPath: "garminApi",
  baseQuery: amplifyGraphqlBaseQuery(),
  endpoints: (builder) => ({
    getGarminActivities: builder.query<GarminActivity[], void>({
      query: () =>
        createQuery({
          query: getGarminActivities,
          getDataFromResponse: (data) => data.getGarminActivities,
        }),
    }),
    importGarminActivity: builder.mutation<GarminActivity, ImportGarminActivityMutationVariables>({
      query: ({ id }) =>
        createQuery<void, ImportGarminActivityMutationVariables>({
          query: importGarminActivity,
          variables: { id },
          getDataFromResponse: (data) => data.importGarminActivity,
        }),
    }),
  }),
});

export const { useGetGarminActivitiesQuery, useImportGarminActivityMutation } = garminApi;
