export const garminSignIn = /* GraphQL */ `
  mutation GarminSignIn($username: String!, $password: String!) {
    garminSignIn(username: $username, password: $password) {
      userProfileId
      username
      emailAddress
    }
  }
`;
export const importGarminActivity = /* GraphQL */ `
  mutation ImportGarminActivity($id: String!) {
    importGarminActivity(id: $id)
  }
`;
