import React from "react";
import { Container } from "@mui/material";
import {
  useGetGarminActivitiesQuery,
  useImportGarminActivityMutation,
} from "features/garmin/garmin-api";
import { GarminActivitiesList, Header } from "common/components";

export function GarminActivitiesImportPage() {
  const { data, isLoading } = useGetGarminActivitiesQuery();
  const [importGarminActivity] = useImportGarminActivityMutation();

  //TODO remove line 22 toString()
  return (
    <div>
      <Header title="Import your Garmin activities" />
      <Container>
        <GarminActivitiesList
          loading={isLoading}
          activities={data || []}
          onClickImportActivity={(activity) => importGarminActivity({ id: activity.id.toString() })}
        />
      </Container>
    </div>
  );
}
