import React, { useState } from "react";
import { Container } from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetWorkoutQuery } from "features/workouts/workouts-api";
import { TabPanel, QueryContainer } from "common/components";
import { TabContextProvider } from "common/components";
import {
  WorkoutStats,
  WorkoutWodDetails,
  WorkoutHeartRates,
  WorkoutSets,
  WorkoutHeader,
} from "features/workouts/components";

const tabs = [
  {
    id: "stats",
    text: "Stats",
  },
  {
    id: "wod",
    text: "WOD",
  },
  {
    id: "hr",
    text: "HR",
  },
  {
    id: "sets",
    text: "Sets",
  },
];

type Params = {
  id: string;
};

export function WorkoutDetails() {
  const [tab, setTab] = useState(0);
  const { id } = useParams<Params>();
  const { data, isFetching, error } = useGetWorkoutQuery({ id: id || "" });

  return (
    <QueryContainer isFetching={isFetching} error={error}>
      <TabContextProvider value={tab}>
        {data && (
          <div>
            <WorkoutHeader activity={data} tabs={tabs} tabValue={tab} onChangeTab={setTab} />
            <Container>
              <TabPanel index={0}>
                <WorkoutStats activity={data} />
              </TabPanel>
              <TabPanel index={1}>
                <WorkoutWodDetails activity={data} />
              </TabPanel>
              <TabPanel index={2}>
                <WorkoutHeartRates activity={data} />
              </TabPanel>
              <TabPanel index={3}>
                <WorkoutSets activity={data} />
              </TabPanel>
            </Container>
          </div>
        )}
      </TabContextProvider>
    </QueryContainer>
  );
}
