import React from "react";
import { deepOrange, grey } from "@mui/material/colors";
import { getWorkoutGroupSetTime } from "features/workouts/workout.types";
import { Annotation, HeartRateChart } from "common/components";
import { Activity } from "app/api-base";

interface Props {
  activity: Activity;
}

export function WorkoutHeartRates({ activity: { heartRate, workout } }: Props) {
  const annotations =
    workout?.items.map(
      (groupSet, index: number): Annotation => ({
        id: `set-${index}`,
        xMin: (groupSet && getWorkoutGroupSetTime(groupSet)) || 0,
        xMax: (groupSet && getWorkoutGroupSetTime(groupSet)) || 0,
        borderColor: groupSet?.type === "WARM_UP" ? grey[400] : deepOrange[400],
        content: groupSet?.type,
        position: "end",
      }),
    ) || [];

  return (
    <div>
      <HeartRateChart avgHR maxHR heartRate={heartRate} annotations={annotations} />
    </div>
  );
}
