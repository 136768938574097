import React from "react";
import { Box, Typography } from "@mui/material";
import { getDisplayExercise } from "features/workouts/workout.types";
import { Activity } from "app/api-base";

interface Props {
  activity: Activity;
}

export function WorkoutWodDetails({ activity }: Props) {
  return (
    <Box>
      {activity.workout?.items.map(
        (group, index) =>
          group && (
            <Box key={index}>
              <Typography variant="h6">{group.type}</Typography>
              <ul>
                <li>Duration: {JSON.stringify(group.wod.duration)}</li>
                {group.wod.steps.map(({ exercise, round }, i) => {
                  if (exercise) {
                    return (
                      <li key={i}>
                        {exercise && getDisplayExercise(exercise.exercise)}
                        <ul>
                          <li>Duration: {JSON.stringify(exercise.duration)}</li>
                          <li>Target: {JSON.stringify(exercise.target)}</li>
                        </ul>
                      </li>
                    );
                  }
                  if (round) {
                    return (
                      <li key={i}>
                        <span>Round {round.index}</span>
                        <ul>
                          <li>Duration: {JSON.stringify(round.duration)}</li>
                          <li>
                            <span>Exercises:</span>
                            <ul>
                              {round.exercises?.map(
                                (e, j) =>
                                  e && (
                                    <li key={j}>
                                      {getDisplayExercise(e.exercise)}
                                      <ul>
                                        <li>Duration: {JSON.stringify(e.duration)}</li>
                                        <li>Target: {JSON.stringify(e.target)}</li>
                                      </ul>
                                    </li>
                                  ),
                              )}
                            </ul>
                          </li>
                        </ul>
                      </li>
                    );
                  }
                })}
              </ul>
            </Box>
          ),
      )}
    </Box>
  );
}
