import moment from "moment";

export const DateTimeFormat = "DD/MM/YYYY hh:mm:ss";
export const DateFormat = "DD/MM/YYYY";
export const TimeFormat = "HH:mm:ss";
export const WorkoutTimeFormat = "mm:ss";

export function formatSeconds(seconds: number, format: string): string {
  return moment(seconds * 1000).format(format);
}
